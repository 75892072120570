@import '../../../assets/styles/dependencias';

.App {
    .cont-header-public{
        width: 100%;
        header{
            width: 100%;
            margin: auto;
            max-width: 1440px;
            padding-top: $regular;
            // color: $secondary_color;
            background-color: transparent;
            .logo{
                width: 310px;
                img{
                    width: 100%;
                    height: auto;
                }
                @include maxBreakpoint($mintable){
                    flex-grow: 1;
                    text-align: center;
                    img{
                        width: 300px;
                        height: auto;
                    }
                }
            }
            .cont-item-registro-inicio{
                button{
                    font-size: $large;
                }
                .active-item{
                    border-bottom: 1px solid $primary_color;
                }
                
            }
            .icon-menu-movil{
                @include minBreakpoint($mintable){
                    width: 90px;
                    text-align: center;
                }
                // @include maxBreakpoint($mintable){
                //     flex-grow: 1;
                //     text-align: right;
                // }
            }
        }
        &.menu-public-fixed{
            top: 0;
            z-index: 4;
            position: fixed;
            transition: all .5s;
            background-color: $blancopalido;
            header{
                padding-top: 0;
                .cont-item-menu-desktop{
                    button{
                        color: $primary_color;
                    }
                }
            }
        }
        .cont-item-registro-inicio-movil{
            @include maxBreakpoint($mintable){
                height: 30px;
                justify-content: center;
                button{
                    font-size: 1.1rem;
                    padding: 0px 8px;
                }
            }
        }
    }
}