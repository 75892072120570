//Primary color
$primary_color: #6857B2;
$primary_color_light: #7e70ba;

//Secondary color
$secondary_color: #595955;
$secondary_color_light: #DAD9D6;

// Colors
$gris-table-heder: #FAFAFA;
$whatsapp: #1BD741;
$google_color: #dd4b39;
$gris-icon: #d8d9dd;

$body : #edf0f1;
$blanco : #ffffff;
$color_nav : #f6f9fd;
$colorlink : #039be5;
$gris-text : #83868d;
$grisPreloader : #f3f3f3;
$blancotransparente : #f7f7f7b3;
$blancopalido : #eaeaea;


$oxfordBlue: #3B4058;
$albescentWhite: #F3E9CB;
$cerulean: #00BDE5;
$bondiBlue: #0083B7;
$casper: #BDCADB;
$wildBlueYonder: #718BAF;
$fiord: #4E5575;
$sepia: #704214;
$rawUmber: #704214;
$zombie: #E4CF92;
$pictonBlue: #4BC0F3;
$ebonyClay: #2D3143;
$fuelYellow: #E9AA28;
$silverTree: #58B996;
$regalBlue: #004777;
$azure: #3A5CA9;
$astronaut: #274077;
$comet: #5B6384;
$laser: #C4A862;
$rss: #FFA401;
$mandy: #FF8882;
$lightBlue: #D6EAF3;
$loblolly: #BEC9D0;
$shuttleGray: #5E6A80;

// Basic Colors
$black: #000;
$red: #FF0000;
$white: #FFF;
$requiredRed: #DB4433;

// Gray Colors
$codGray: #111;
$mineShaft: #222;
$tuna: #333;
$tundora: #444;
$emperor: #555;
$doveGray: #666;
$boulder: #777;
$gray: #F8F8F8;
$dustyGray: #999;
$silverChalice: #AAA;
$silver: #BBB;
$frenchGray: #CCC;
$alto: #DDD;
$gallery: #EEEE;
$mercury: #E9E9E9;
$alabaster: #F7F7F7;
$almostWhite: #f1f2f6;
$steelGray: #1E1E2E;
$storGray: #6C6E86;
$shark: #21262D;
$darkShark: #1C1E25;
$manatee: #d8d7dcb5;

// Reds/Pinks:
$radicalRed: #FF2D55;
$torchRed: #FC1C49;
$pink: #FFC3CD;
$vividTangerine: #FF8882;
$lightRed: #FF8080;

// Blues:
$easternBlue: #1D9A90;
$lightGreen: #0D9A67;
$lochmara: #0073BC;
$pictonBlue: #42B3EB;
$skyBlue: #41ADE2;
$bigStone: #172B44;
$prussianBlue: #002642;
$oxfordBlue: #364150;
$chambray: #3B5998;
$regentGray: #8290A3;
$dodgerBlue: #2EA1F8;
$azureRadiance: #00A8FF;
$mediumBlue: #C6E6FD;
$litghtlyBlue: #C3D2DC;

// Yellow/Green:
$ripeLemon: #F6D42A;
$galliano: #DCB909;
$yukonGold: #7A6705;

// React
$frenchPass: #BBF0FF;
$malibu: #5BDAFD;

// Redux
$fuchsiaBlue: #7745BF;

// HTML5
$waxFlower: #FFC1AF;

// Node
$chiffon: #E4FFC3;

// Javascript
$ronchi: #F0DB50;

// States
$crimson: #ED1C24;
$malachite: rgb(94, 197, 111);
$treePoppy: #FD9A18;
$lightOrange: #FFB73D;

// Sign in button
$amethystSmoke: #9498BB;
$blackcurrant: #2E293C;
$pigeonPost: #B2B9D6;

// Danger button
$thunderbird: #D02718;
$grenadier: #C62D1F;
$geraldine: #F5978E;
$flamingo: #F24537;
$lightPink: #F998AB;

// Primary button
$chathamsBlue: #124D77;
$endeavour: #0061A7;
$cornflowerBlue: #54A3F7;

// Success button
$fernFrond: #3B6E22;
$chelseaCucumber: #68A54B;
$deYork: #9ACC85;
$aquaForest: #74AD5A;

// Warning button
$mySin: #FFAA22;
$sunshade: #FFAB23;
$buttermilk: #FFF6AF;
$parisDaisy: #FFEC64;

// Social colors
$facebook: #3B5998;
$twitter: #1DA1F2;
$linkedin: #0077B5;
$rss: #ff6600;

// Rgba.
$greyRgba: rgba(236, 239, 243, 0.8);
$lightRgbaBlue: rgba(69, 191, 245, 0.17);

// Fonts
$openSans: 'OpenSans';
$poppins: 'Poppins';

// Font sizes
$xxxLarge: 48px;
$xxLarge: 32px;
$xLarge: 24px;
$larger: 19px;
$large: 18px;
$big: 17px;
$regular: 16px;
$medium: 14px;
$small: 13px;
$smaller: 12px;
$xSmall: 10px;
$xxSmall: 9px;
$xxxSmall: 8px;

// Font weight
$light: 100;
$normal: 400;
$bold: 700;
$heavy: 900;

// Breakpoints
$tablet: 768px;
$mobile: 640px;
$desktop: 1024px;

$minDektop: 960px;
$mintable: 600px;