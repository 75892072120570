@import '../../../assets/styles/dependencias';

.cont-list-post{
    width: 100%;
    padding: 0 8px;
    @include minBreakpoint($minDektop){
       padding: 0 8px 0 0; 
    }
    .post{
        .header-post{
            display: flex;
            margin-bottom: 12px;
            .link-epage{
                width: 100%;
                display: flex;
            }
            .autor-fech{
                width: 90%;
                padding-top: 5px;
                padding-left: 12px;
                span {
                    line-height: 1.3;
                    &:first-child{
                        display: block;
                        font-weight: bold;
                    }
                    &:last-child{
                        font-size: 0.7rem;
                    }
                }
            }
        }
        .body-post{
            .cont-text{
                overflow-x: hidden;
                span{
                    font-size: 1rem;
                }
            }
            .cont-img-post{
                width: 100%;
                height: auto;
                text-align: center;
                img {
                    width: 100%;
                    height: auto;
                }
            }
            .cont-video-post{
                height: 0;
                width: 100%;
                margin: 0 auto;
                position: relative;
                padding-bottom: 56.25% ;/* 16:9 Aspect Ratio */ 
                video{
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                }
            }
        }
    }
}