@import './_vars';

body {
    background-color: $white !important;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto Bold', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input {
    appearance: none;
    background-clip: padding-box;
    outline: none;
    border: none;
}

a {
    color: $secondary_color;
    text-decoration: none !important;
    &:hover{
        color: $primary_color_light;
    }
}

button{
    box-shadow: none !important;
    text-transform: initial !important;
}

.cont-progres{
	width: 100px;
	height: auto;
	text-align: center;
	margin: .5rem auto;
	div{
		width: 50px;
		height: 50px;
	}
	span{
		color: $dustyGray;
	}
}