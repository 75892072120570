@mixin breakpoint($minSize, $maxSize) {
  @media only screen and (min-width: $minSize) and (max-width: $maxSize) {
    @content;
  }
}

@mixin maxBreakpoint($size) {
  @media only screen and (max-width: $size) {
    @content;
  }
}

@mixin minBreakpoint($size) {
  @media screen and (min-width: $size) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $tablet) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $mobile) {
    @content;
  }
}

@mixin desktop {
  @media (max-width: $desktop) {
    @content;
  }
}

@mixin placeholder($color) {
  ::-webkit-input-placeholder {
    color: $color;
  }

  :-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  ::-moz-placeholder {
    color: $color;
    opacity: 1;
  }

  ::-ms-input-placeholder {
    color: $color;
  }
}

@mixin prefix($property, $value) {
  -webkit-#{$property}: $value;
  -moz-#{$property}: $value;
  -o-#{$property}: $value;
  -ms-#{$property}: $value;
  #{$property}: $value;
}