@import '../../../assets/styles/_dependencias';

.bodyError{
    margin: auto;
    overflow: hidden;
    // margin-top: 60px;
    max-width: 1440px;
    text-align: center;
    color:$primary_color;
    position: relative;
    height: 80vh;
    @include maxBreakpoint($mintable){
        height: calc(100vh - 115px);
    }
}
.z-indez-01{
    z-index: -1;
}
.c{
    position: absolute;
    margin: 5% auto;
    left: 0;
    right: 0;
    @include mobile {
        margin: 100px auto;
    }
}
.c-user{
    @include minBreakpoint($minDektop){
        padding-left: 230px;
    }
}

._404{
    font-size: 220px;
    position: relative;
    display: inline-block;
    z-index: 2;
    height: 250px;
    letter-spacing: 15px;
    
    @include mobile {
        font-size: 140px;
        height: 190px;
    }
}
._1{
    text-align:center;
    display:block;
    position:relative;
    letter-spacing: 12px;
    font-size: 4em;
    line-height: 80%;
    @include mobile {
        font-size: 3em;
    }
}
._2{
    text-align:center;
    display:block;
    position: relative;
    font-size: 20px;
}
.text{
    font-size: 70px;
    text-align: center;
    position: relative;
    display: inline-block;
    margin: 19px 0px 0px 0px;
    /* top: 256.301px; */
    z-index: 3;
    width: 100%;
    line-height: 1.2em;
    display: inline-block;
}


.bodyError .btn{
    background-color: $primary_color;
    position: relative;
    display: inline-block;
    width: 358px;
    padding: 5px;
    z-index: 3;
    font-size: 25px;
    margin:0 auto;
    color:$white;
    text-decoration: none;
    margin-right: 10px;
    padding: .5rem;
    border-radius: 5px;
    height: auto;
    &:hover{
        background-color: $primary_color;
    }
    @include mobile {
        width: 250px;
    }
}
.bodyError hr{
    padding: 0;
    border: none;
    border-top: 5px solid $primary_color;
    color: $primary_color;
    text-align: center;
    margin: 0px auto;
    width: 420px !important;
    height:10px;
    z-index: -10;
    @include mobile {
        width: 330px;
    }
}

hr:after {
    content: "\2022";
    display: inline-block;
    position: relative;
    top: -0.75em;
    font-size: 5em;
    padding: 0 0.2em;
}

.cloud {
    width: 350px; 
    height: 120px;
    text-align: center;

    background: #FFF;
    background: linear-gradient(to top, #FFF 100%);
    background: -webkit-linear-gradient(to top, #FFF 100%);
    background: -moz-linear-gradient(to top, #FFF 100%);
    background: -ms-linear-gradient(to top, #FFF 100%);
    background: -o-linear-gradient(to top, #FFF 100%);

    border-radius: 100px;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;

    position: absolute;
    margin: 120px auto 20px;
    z-index:-1;
    transition: ease 1s;
    z-index: 1;
}