@import '../../../assets/styles/dependencias';

.container{
    min-height: 100vh;
    margin: 0 auto;
    text-align: center;
    position: relative;
    background-color: $almostWhite;
    .cont-login{
        position: relative;
        min-height: 100vh;
        .cont-paper{
            height: 100%;
            .paper-login{
                width: auto;
                padding: 1rem;
                margin-top: 1rem;
                .cont-progres{
                    width: 100px;
                    height: auto;
                    text-align: center;
                    margin: .5rem auto;
                    div{
                        width: 50px;
                        height: 50px;
                    }
                    span{
                        color: $dustyGray;
                    }
                }
                .aviso-msg{
                    width: 300px;
                    text-align: justify;
                    button{
                        min-width: 40px;
                    }
                }
                .header-paper-login{
                    article{
                        margin-bottom: 1.5rem;
                    }
                    h5{
                        font-weight: $bold;
                    }
                }
                .body-paper-login{
                    margin-top: .5rem;
                    .cont-imput-body-paper-login{
                        padding: .5rem 0;
                        &.message{
                            width: 250px;
                            text-align: justify;
                            span{
                                font-size: $small;
                            }
                        }
                    }
                }
                .footer-paper-login{
                    margin-top: .5rem;
                    text-align: justify;
                    a{
                        display: block;
                        margin-top: .5rem;
                        color: $primary_color;
                        span{
                            font-size: $small;
                        }
                    }
                    .btn-google,
                    .btn-at{
                        color: $white;
                        span{
                            i{
                                top: 10px;
                                left: 10px;
                                position: absolute;
                            }
                        }
                        &[class*="disabled"]{
                            background-color: rgba(0, 0, 0, 0.12);
                            span{
                                color: rgba(0, 0, 0, 0.26);
                            }
                        }
                    }
                    .btn-at{
                        margin-top: 5px;
                        background-color: $primary_color;
                    }
                    .btn-google{
                        background-color: $google_color;
                    }
                }
                // @include minBreakpoint($mintable){
                //     width: 285px;
                // }
            }
            .logo{
                width: 100%;
                margin-top: 30px;
                text-align: center;
                img{
                    width: 100px;
                }
            }
        }
    }
}

.cont-solicitud-demo{
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    background-color: rgb(255, 255, 255);
    .sombra{
        width: 100%;
        height: 100vh;
        opacity: 0.65;
        position: absolute;
        background-color: rgb(0, 0, 0);
        transition: background 0.3s ease 0s, border-radius 0.3s ease 0s, opacity 0.3s ease 0s;
    }
    .paper-demo{
        position: relative;
        width: auto;
        padding: 1rem;
        margin-top: 1rem;
        box-shadow: none;
        background-color: transparent;
        .title{
            color: rgb(255, 255, 255);
        }
        .btn{
            margin-top: 1rem;
            background-color: $white;
            color: black;
            text-transform: uppercase !important;
        }
        .input-demo{
            label{
                color: $white;
            }
            .MuiInputBase-root{
                color: white;
                fieldset{
                    border-color: $white;
                }
            }
        }
    }
}
