@import '../../assets/styles/dependencias';


.container-public{
    width: 100%;
    height: auto;
    .MuiTypography-body1{
        font-size: 1.2rem;
    }
    .max-width-container{
        width: 100%;
        margin: auto;
        max-width: 1440px;
        padding-left: 37px;
        padding-right: 24px;
        box-sizing: border-box;
        // height: 500px;
    }
    .baner-home{
        padding-top: 80px;
        margin-top: -80px;
        width: 100%;
        min-height: calc(100vh - 80px);
        background-image:  url('https://ecardioline.com/assets_media/bg/bg1.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        // background-attachment: fixed;
        background-size: cover;
        @include maxBreakpoint($mintable){
            height: calc(100vh - 115px);
            margin-top: -115px;
            padding-top: 115px;
        }
        .contenido-baner{
            width: 100%;
            margin: auto;
            margin-top: 60%;
            max-width: 1440px;
            padding-left: 37px;
            padding-right: 24px;
            box-sizing: border-box;
            .clear-float-right{
                clear: right
            }
            .cont-slogan-one{
                height: 200px;
            }
            .slogan{
                width: 100%;
                color: $white;
                font-size: 1.9rem;
                text-align: center;
                margin-bottom: 1rem;
                font-weight: 600;
                float: right;
                @include minBreakpoint($mintable){
                    width: 50%;
                    //text-align: right;
                    font-size: 2.125rem;
                }
                @include minBreakpoint($minDektop){
                    width: 45%;
                }
                &.right{
                    @include minBreakpoint($mintable){
                        float: right;
                        text-align: right;
                    }
                }
                .btn-consultar-ahora{
                    font-size: 1.9rem;
                    border: 2px solid;
                    margin-top: 1.5rem;
                    border-color: $primary_color;
                    background-color: $primary_color;
                    @include minBreakpoint($mintable){
                        font-size: 2.125rem;
                    }
                    &.white{
                        color: $white;
                    }
                }
            }
            @include minBreakpoint($mintable){
                height: 1%;
                margin-top: 2%;
                overflow: hidden;
            }
            @include minBreakpoint($minDektop){
                margin-top: 6%;
            }
        }
    }
    .baners-page{
        width: 100%;
        min-height: 270px;
        padding-top: 80px;
        margin-top: -80px;
        border-radius: inherit;
        // background-size: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-color: $secondary_color_light;
        .contenido-baner{
            width: 100%;
            margin: auto;
            height: 270px;
            max-width: 1440px;
            padding-left: 37px;
            position: relative;
            padding-right: 24px;
            box-sizing: border-box;
            .slogan{
                color: $white;
                font-size: 1.9rem;
                font-weight: 600;
                @include minBreakpoint($mintable){
                    font-size: 2.125rem;
                }
            }
            
        }
        @include maxBreakpoint($mintable){
            margin-top: -115px;
            padding-top: 115px;
        }
        @include minBreakpoint($mintable){
            height: 1%;
            overflow: hidden;
        }
        &.baner-servicios{
            background-image:  url('https://ecardioline.com/assets_media/bg/bg6.jpg');
            .contenido-baner{
                .slogan{
                    bottom: 45px;
                    position: absolute;
                }
            }
        }
        &.baner-especialistas{
            height: 370px;
            background-image:  url('https://ecardioline.com/assets_media/bg/bg15.jpg');
            .contenido-baner{
                .slogan{
                    margin-top: 1.5rem;
                    margin-bottom: 1.5rem;
                    text-align: center;
                }
                .input-especialista{
                    border-radius: 5px;
                    background-color: $white;
                }
                @include minBreakpoint($mintable){
                    padding: 0 15%;
                }
            }
            &.baner-especialistas-home{
                height: auto;
                margin-top: 0px;
                padding-bottom: 50px;
                .contenido-baner{
                    height: auto;
                    .cont-especialidades{
                        color: $white;
                        text-align: justify;
                        span{
                            font-size: 1.2rem;
                        }
                        .ver-mas{
                            color: $white;
                            font-size: 1.2rem;
                            text-decoration: underline !important;
                            p{
                                font-weight: 600;
                                margin-top: 1rem;
                            }
                        }
                    }
                }
            }
        }
        &.baner-conocenos{
            min-height: 150px;
            background-color: $white;
            .contenido-baner{
                height: auto;
                h4{
                    padding-top: 1.5rem;
                    text-align: center;
                    color: $secondary_color;
                    @include minBreakpoint($mintable){
                        padding-top: 3rem;
                    }
                }
                @include minBreakpoint($mintable){
                    height: 180px;
                }
                @include minBreakpoint($minDektop){
                    height: 130px;
                }
            }
            .txt-fondo-gris{
                padding: 2rem 24px 2rem 37px;
                background-color: $blancopalido;
                div{
                    width: 100%;
                    margin: auto;
                    max-width: 1440px;
                }
            }
            &.baner-con-texto{
                padding: 2px;
                margin-top: 0;
                height: 400px;
                background-image:  url('https://ecardioline.com/assets_media/bg/bg9.jpg');
                p{
                    width: 80%;
                    color: $white;
                    font-weight: 600;
                    font-size: 1.4rem;
                    margin: 6rem auto;
                    @include minBreakpoint($mintable){
                        width: 70%;
                        font-size: 2rem;
                        margin: 8rem auto;
                    }
                }
            }
        }
        &.baner-modalidad{
            min-height: 150px;
            background-color: $white;
            .contenido-baner{
                height: 150px;
                h4{
                    padding-top: 3rem;
                    text-align: center;
                    color: $primary_color;
                }
            }
        }
        &.baner-landingEspecialista{
            min-height: 175px;
            background-color: $blancopalido;
            .contenido-baner{
                height: auto;
                text-align: center;
                h4{
                    padding-top: 1.5rem;
                    padding-bottom: 1.5rem;
                    text-align: center;
                    color: $primary_color;
                }
                @include minBreakpoint($mintable){
                    width: 60%;
                }
            }
        }
        &.baner-preguntas{
            background-image:  url('https://ecardioline.com/assets_media/bg/bg14.jpg');
            .contenido-baner{
                .slogan{
                    padding-top: 4rem;
                    text-align: center;
                }
            }
        }
        &.baner-contacto{
            padding-top: 125px;
            margin-top: auto;
            background-image:  url('https://ecardioline.com/assets_media/bg/bg9.jpg');
            .contenido-baner{
                .slogan{
                    padding-top: 3rem;
                    font-size: 2.4rem;
                    text-align: center;
                    @include minBreakpoint($mintable){
                        font-size: 2.6rem;
                    }
                }
            }
        }
        &.baner-publicaciones{
            background-image:  url('https://ecardioline.com/assets_media/bg/bg16.jpg');
            .contenido-baner{
                .slogan{
                    bottom: 50%;
                    left: 8%;
                    position: absolute;
                    @include minBreakpoint($mintable){
                        left: 15%;
                        font-size: 4.9rem;
                    }
                }
            }
        }
        &.baner-blog{
            background-color: $white;
            .contenido-baner{
                padding-top: 3rem;
                .slogan{
                    text-align: center;
                    // left: 2%;
                    // bottom: 50%;
                    color: $secondary_color;
                    // position: absolute;
                    &._two{
                        margin-top: 2rem;
                        font-weight: 400;
                    }
                }
            }
        }
    }
    .cont-video{
        height: 0;
        overflow: hidden;
        position: relative;
        padding-bottom: 56.25%;
        iframe{
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            position: absolute;
        }
    }
    .como-funciona{
        .icon-como-funciona{
            width: 110px;
            height: 110px;
            overflow: initial;
            margin-bottom: 1.5rem;
        }
        .icon-como-num{
            width: 110px;
            height: 110px;
            overflow: initial;
            font-size: 2.3rem;
            color: $primary_color;
            background-color: $white;
            margin-bottom: 1.5rem;
            //border: 2px solid $primary_color;
        }
        .cont-item-como-funciona{
            .item-funciona{
                padding: 0;
                margin: 8px;
                border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                .cont-body-item-funciona{
                    @include minBreakpoint($mintable){
                        min-height: 215px;
                    }
                    @include minBreakpoint($minDektop){
                        min-height: 180px;
                    }
                }
            }
            @include minBreakpoint($mintable){
                flex-grow: 1;
                flex-wrap: initial;
            }
        }
        .cont-img-medico{
            width: 100%;
            height: auto;
            img{
                width: 100%;
                height: auto;
            }
        }
        .icon-landing{
            width: 120px;
            height: 120px;
            overflow: initial;
            margin-bottom: 1.5rem;
        }
        .landing-page{
            p{
                font-weight: 400;
            }
        }
    }
    .modalidades{
        background-color: transparent;
        .item-modalidad{
            margin-top: 2rem;
            @include minBreakpoint($mintable){
                margin-bottom: 2rem;
            }
            .cont-img{
                width: 100%;
                height: auto;
                img{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    color: transparent;
                    text-align: center;
                    text-indent: 10000px;
                }
            }
            .cont-text-left{
                @include minBreakpoint($mintable){
                    padding-left: 2rem;
                }
            }
            .cont-text-right{
                @include minBreakpoint($mintable){
                    padding-right: 2rem;
                }
            }
            &.invertir{
                @include maxBreakpoint($mintable){
                    flex-direction: column-reverse;
                }
            }
        }
    }
    .cont-img-publicaciones{
        margin-top: 0;
        background-image:  url('https://ecardioline.com/assets_media/bg/bg16.jpg');
        background-position: center center;
        background-repeat: no-repeat;
        //background-size: 100%;
        text-align: center;
        padding: 1rem;
        @include minBreakpoint($mintable){
            height: 500px;
        }
        p{
            width: 100%;
            color: $white;
            font-weight: 600;
            font-size: 1.6rem;
            text-shadow: 3px 3px 3px $secondary_color;
            @include minBreakpoint($mintable){
                width: 80%;
                font-size: 2.125rem;
                margin: 8rem 5rem auto;
            }
            @include minBreakpoint($minDektop){
                margin: 8rem 9rem auto;
            }
        }
    }
    .especialistas{
        .cont-item-especialistas{
            .card-item{
                overflow: hidden;
                margin-bottom: 2rem;
                position: relative;
                // padding: 0;
                // margin: 8px;
                // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                .body-card{
                    padding: 10px;
                    border-radius: 5px;
                    border: 1px solid rgba(0, 0, 0, 0.12);
                }
                .img-especialista{
                    width: 255px;
                    height: 255px;
                    margin: auto;
                    // overflow: initial;
                    margin-bottom: 1.5rem;
                    // background-color: $primary_color;
                }
                .card-text{
                    min-height: 180px;
                    text-align: center;
                }
            }
            @include minBreakpoint($mintable){
                flex-grow: 1;
                // flex-wrap: initial;
            }
        }
    }
    .testimonios{
        background-color: transparent;
        .cont-text-testimonio{
            width: 70%;
        }
    }
    .formaDePagos{
        @include maxBreakpoint($mintable){
            img{
                width: 100%;
                height: auto;
            }
        }
    }
    .contactanos{
        padding-top: 2rem;
        margin-bottom: 3rem;
    }
    .btn-with-br{
        font-size: 1.1rem;
        border: 2px solid;
        font-weight: 400;
        padding: 12px 16px;
        // margin-top: 1.5rem;
        &.white{
            color: $white;
        }
        &.bg-primary{
            color: $white;
            border-color: $primary_color;
            background-color: $primary_color;
        }
    }
    .container-servicios{
        .title-container{
            text-align: center;
            @include minBreakpoint($mintable){
                text-align: justify;
                box-sizing: border-box;
            }
        }
        .aqui{
            color: $primary_color;
            text-decoration: underline !important;
        }
        .cont-texto{
            margin-bottom: 2rem;
            .texto-item{
                display: flex;
                margin-top: 2rem;
                .icon-check{
                    margin-right: 1rem;
                    color: $primary_color;
                    background-color: transparent;
                    border: 2px solid $primary_color;
                }
            }
            .icon-beneficios{
                width: 110px;
                height: 110px;
                overflow: initial;
            }
        }
        .cont-img-and-text{
            .img-cont{
                margin-bottom: 2rem;
                width: 100%;
                img{
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    .container-conoenos{
        .text-conocenos,
        .cont-img-conocenos{
            width: 100%;
            height: auto;
            margin: auto;
            img{
                width: 100%;
                height: auto;
            }
            @include minBreakpoint($mintable){
                width: 80%;
            }
        }
        .testimonio{
            padding: 1rem;
            text-align: center;
            background-color: $blancopalido;
            .icon-woman{
                width: 60px;
                height: 60px;
                margin: auto;
                margin-bottom: 1rem;
            }
        }
        // .cont-img-conocenos-tow{
        //     min-height: 300px;
        //     background-image:  url('https://ecardioline.com/assets_media/bg/bg9.jpg');
        //     background-position: center center;
        //     background-repeat: no-repeat;
        //     background-size: 100%;
        //     padding: 2rem;
        //     p{
        //         width: 80%;
        //         color: $white;
        //         font-weight: 600;
        //         font-size: 1.2rem;
        //         margin: 6rem auto;
        //     }
        // }
        .equipo{
            color: $primary_color;
            text-decoration: underline !important;  
        }
    }
    .container-preguntas{
        width: 80%;
        box-sizing: border-box;
        .cont-texto{
            margin-bottom: 1rem;
            box-sizing: border-box;
            .title{
                display: flex;
                align-items: center;
                cursor: pointer;
                .arrow{
                    transition: all .5s;
                }
                .active{
                    transform: rotate(-90deg);
                    transition: all .5s;
                }
            }
            // @include minBreakpoint($mintable){
            //     padding-left: 12.5%;
            //     padding-right: 12.5%;
            // }
            .respuesta{
                height: 0;
                opacity: 0;
                width: 90%;
                overflow: auto;
                transition: all 1s;
                text-align: justify;
                padding-left: 2.5rem;
                &.active{
                    opacity: 1;
                    height: auto;
                    overflow: initial;
                    margin-bottom: 3rem;
                    // display: block;
                    transition: all 1s;
                }
                .cont-img{
                    margin-top: 1.5rem;
                    margin-bottom: 1rem;
                    text-align: center;
                }
            }
        }
        .aqui{
            text-decoration: underline !important;
        }
    }
    .container-contacto{
        .contactanos{
            padding-top: 0;
            margin-bottom: 4rem;
            margin-top: 0 !important;
            hr{
                background-color: transparent;
            }
        }
    }
    .container-modalidades{
        position: relative;
        margin-bottom: 3rem;
        @include minBreakpoint($mintable){
            height: 1%;
            overflow: hidden;
        }
        .cont-img-modalidad{
            width: 90%;
            @include minBreakpoint($mintable){
                width: 50%;
            }
            img{
                width: 100%;
            }
        }
        .cont-text{
            width: 97%;
            padding: 2rem;
            margin-left: 6%;
            min-height: 200px;
            margin-top: -50px;
            position: relative;
            box-sizing: border-box;
            background-color: $blancopalido;
            .texto-item{
                display: flex;
                margin-top: 2rem;
                .icon-check{
                    margin-right: 1rem;
                    color: $primary_color;
                    background-color: transparent;
                    border: 2px solid $primary_color;
                }
            }
            @include minBreakpoint($mintable){
                width: 57%;
                float: right;
                margin-top: -45%;
                // margin-right: 9%;
                margin-left: inherit;
            }
        }
    }
    .container-publicaciones{
        .cont-item-publicacion{
             .card-item{
                overflow: hidden;
                margin-bottom: 2rem;
                position: relative;
                // padding: 0;
                // margin: 8px;
                // border-bottom: 1px solid rgba(0, 0, 0, 0.12);
                .card-header{
                    position: relative;
                    .portada{
                        .img-portada{
                            width: auto;
                            height: 250px;
                            overflow: initial;
                            margin-bottom: 1.5rem;
                            @include minBreakpoint($mintable){
                                height: 300px;
                            }
                        }
                    }
                    .img-autor{
                        right: 12px;
                        width: 100px;
                        height: 100px;
                        bottom: -40px;
                        position: absolute;
                        @include minBreakpoint($mintable){
                            right: 36px;
                            width: 150px;
                            bottom: -60px;
                            height: 150px;
                        }
                    }
                }
                .card-body{
                    height: 300px;
                    overflow: hidden;
                    margin-bottom: 1rem;
                }
                .card-footer{
                    .leer-mas{
                        text-decoration: underline !important;
                    }
                    .nombre-especialista{
                        padding-right: 1rem;
                        font-weight: 600;
                    }
                }
                .separador{
                    bottom: 0;
                    width: 96% !important;
                    position: absolute;
                }
            }
            @include minBreakpoint($mintable){
                flex-grow: 1;
                // flex-wrap: initial;
            }
        }
    }
}


.footer-public{
    width: 100%;
    margin: auto;
    padding-top: 2rem;
    max-width: 1440px;
    padding-left: 24px;
    padding-right: 24px;
    box-sizing: border-box;
    .cont-icon-redes{
        display: flex;
        margin-bottom: 2rem;
        .icon-redes{
            font-size: 1.5rem;
            padding-right: 1rem;
            color: $primary_color;
        }
    }
    .num-contacto{
        margin-bottom: 2.5rem;
        a{
            display: flex;
            align-items: center;
            color: $secondary_color !important;
        }
        .icon-ws{
            font-size: 1.5rem;
            margin-right: .5rem;
        }
    }
}