@import './components/assets/styles/dependencias';

.notify{
    &.error{
        div{
            background-color: $flamingo;
        }
    }
    &.success{
        div{
            background-color: $malachite;
        }
    }
    &.info{
        div{
            background-color: $cornflowerBlue;
        }
    }
}
.ProgressAbsolut{
  position: absolute;
}

.pointer{ cursor: pointer; }
.flex{display: flex;}
hr{
    width: 100% !important;
    &::after{
        display: none !important;
    }
}
.cont-btn{
    button{
        &:first-child{
            margin-right: .8rem;
        }
    }
}

/*Widths and height*/
.w100{width: 100%;}
.mn-width-auto{min-width: auto !important;}
.mx-width-300{max-width: 300px !important;}
.mn-height-auto{min-height: auto !important;}
/*Widths and height*/

.bx-shadow-n{ box-shadow: none !important; }
/* Margins */
.m-a{ margin: auto !important; }
.mt-0{ margin-top: 0 !important; }
.mt-05{ margin-top: .5rem !important; }
.mt-1{ margin-top: 1rem !important; }
.mt-2{ margin-top: 2rem !important; }
.mt-5{ margin-top: 5rem !important; }
.mb-05{ margin-bottom: .5rem !important; }
.mb-0{ margin-bottom: 0 !important; }
.mb-1{ margin-bottom: 1rem !important; }
.mb-2{ margin-bottom: 2rem !important; }
.ml-1{ margin-left: 1rem !important; }
.ml-3{ margin-left: 3rem !important; }
.mr-1{ margin-right: 1rem !important; }
.mr-05{ margin-right: .5rem !important; }
.ml-05{ margin-left: .5rem !important; }
/* Margins */

/*Text*/
.upercase{ text-transform: uppercase !important;}
.transfor_initial{ text-transform: initial !important;}
.txt-algn-end{ text-align: end !important; }
.txt-algn-left{ text-align: left !important; }
.txt-algn-justify{ text-align: justify !important; }
.txt-algn-right{ text-align: right !important; }
.txt-algn-center{ text-align: center !important; }
.txt-justify-center{ justify-content: center !important; }
.txt-shadow{ text-shadow: 3px 2px 3px $secondary_color; }
.underline{ text-decoration: underline !important;}
/*Text*/

/*Font*/
.fs-italic{font-style: italic;}
.fz-13{ font-size: 13px !important;}
.fz-1{ font-size: 1rem !important;}
.fw-300{ font-weight: 300 !important;}
.fw-400{ font-weight: 400 !important;}
.fw-bold{ font-weight: 600 !important;}
.fz-slogan{
    font-size: 3rem !important;
    @include minBreakpoint($mintable){
        font-size: 4.9rem !important;
    }
    &.slogan-movil{
        font-size: 2.4rem !important;
        @include minBreakpoint($mintable){
            font-size: 2.6rem !important;
        }
    }
}
/*Font*/

/*Paddings*/
.p05{padding: .5rem !important;}
.p-0{padding: 0px !important;}
.p-1{padding: 1rem !important;}
.pt-0{padding-top: 0px !important;}
.pt-05{padding-top: .5rem !important;}
.pt-2{padding-top: 2rem !important;}
.p-12{padding: 12px !important;}
.pb-0{padding-bottom: 0px !important;}
.pb-1{padding-bottom: 1rem !important;}
.pl-0{padding-left: 0px !important;}
.pl-05{padding-left: .5rem !important;}
.pl-1{padding-left: 1rem !important;}
.pr-0{padding-right: 0px !important;}
.pr-05{padding-right: .5rem !important;}
.pr-1{padding-right: 1rem !important;}
/*Paddings*/

/*Positions*/
.top-0{ top: 0 !important;}
.relative{ position: relative !important;}
.absolute{ position: absolute !important;}
/*Positions*/

.truncate-text{
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/*Colores para texto*/
.text-primary{ color: $primary_color !important; }
.text-malachite{ color: $malachite !important; }
.text-gris{ color: $gris-text !important; }

.float-l{ float: left }
.float-r{ float: right }

.label-new-carta{
    color : #757582;
    font-size: $smaller !important;
}

.flex-grow-1{ flex-grow: 1 }
.flex-grow-2{ flex-grow: 2 }
.flex-grow-3{ flex-grow: 3 }

.avatar-ws{
    width: 30px !important;
}

.bg-blanco{background-color: $white !important}
.bg-terciario{background-color: $blancopalido}
