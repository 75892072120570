@import '../../../assets/styles/dependencias';

.container-epage{
    width: 100%;
    height: auto;
    .max-width-container{
        width: 100%;
        margin: auto;
        max-width: 1440px;
        padding-left: 24px;
        padding-right: 24px;
        box-sizing: border-box;
        &.p-r-l-0{
            padding-left: 0;
            padding-right: 0;
        }
    }

    .portada{
        border-radius: 0;
        .cont-foto-portada{
            width: 100%;
            height: 100px;
            margin-top: -115px;
            padding-top: 115px;
            border-radius: inherit;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: center center;
            background-color: $secondary_color_light;
            @include minBreakpoint($mintable){
                height: 200px;
                padding-top: 80px;
                margin-top: -80px;
            }
            @include minBreakpoint($minDektop){
                height: 270px;
            }
        }
        .cont-bar-action{
            width: 100%;
            border: 1px solid $manatee;
            .img-and-btn-follow{
                height: 50px;
                position: relative;
                .cont-img-perfil{
                    top: -75px;
                    padding-left: 1rem;
                    position: absolute;
                    .foto-perfil{
                        width: 120px;
                        height: 120px;
                        @include minBreakpoint($mintable){
                            width: 170px;
                            height: 170px;
                        }
                        @include minBreakpoint($minDektop){
                            width: 200px;
                            height: 200px;
                        }
                    }
                    @include minBreakpoint($mintable){
                        top: -125px;
                    }
                    @include minBreakpoint($minDektop){
                       top: -155px;
                    }
                }
                .btn-with-br{
                    float: right;
                    margin-top: 4px;
                    margin-right: 24px;
                }
            }
            .br-top{
                border-top: 1px solid $manatee;
            }
            .br-right{
                border-right: 1px solid $manatee;
            }
            .name-user{
                font-size: 1.3rem;
                font-weight: bold;
            }
            .time-regis{
                margin-bottom: 5px;
                font-size: .7rem;
            }
            .cont-bloques{
                display: flex;
                .bloque{
                    width: 20%;
                    text-align: center;
                }
            }
            .p-r-l-24{
                padding-left: 24px;
                padding-right: 24px;
            }
        }
    }
    .body-epage{
        width: 100%;
        margin: auto;
        display: flex;
        margin-top: 1rem;
        max-width: 1440px;
        box-sizing: border-box; 
        .barleft{
            @include minBreakpoint($minDektop){
                width: 400px;
                padding-right: 12px;
            }
            .card-extracto{
                display: none;
                @include minBreakpoint($minDektop){
                    display: block;
                }
            }
        }
        .cont-page{
            width: 100%;
            @include minBreakpoint($minDektop){
                width: calc(100% - 400px);
            }
        }

        .paper{
            padding: 12px;
            margin-bottom: 1rem;
            box-shadow: 0 1px 5px 0 rgba(0,0,0,0.2), 0 2px 2px 0 rgba(0,0,0,0.14), 0 0px 1px -2px rgba(0,0,0,0.12);
            .title-paper{
                margin-bottom: .5rem;
                span{
                    margin-bottom: .5rem;
                    font-size: 12px;
                    font-weight: 600;
                    line-height: 14px;
                    display: inline-block;
                }
                .nombre-user{
                    font-size: 1.3rem;
                    margin-bottom: .3rem;
                }
                .time-in-platafor{
                    font-size: .75rem;
                }
            }
        }
    }
    .btn-with-br{
        border: 2px solid;
        font-weight: 400;
        font-size: 1.1rem;
        &.font-sz-09{
            margin-top: 3px;
            font-size: .9rem;
        }
    }
    .cursor-pointer{
        cursor: pointer;
    }
    .p-movil{
        margin-bottom: 1rem;
        padding: 0 8px;
    }
}