@import '../../../assets/styles/dependencias';

.App {
    .cont-header{
        width: 100%;
        margin: auto;
        margin-top: 50px;
        max-width: 1440px;
        .appbar{
            box-shadow: none;
            color: $white;
            // background-color: $primary_color;
            .toolbar{
                width: 100%;
                margin: auto;
                min-height: 48px;
                max-width: 1440px;
                padding: 0;
                .icon-menu{
                    display: none;
                    @include maxBreakpoint($minDektop){
                        display: block;
                    }
                }
                .info-nav{
                    flex-grow: 1;
                    .logo-avatar{
                        width: 200px;
                        height: 40px;
                        margin: auto;
                        border-radius: initial;
                        // img{
                        //     object-fit: fill;
                        // }
                        @include minBreakpoint($mintable){
                            margin-left: .5rem;
                        }
                    }
                }
                h6{
                    padding: .7rem;
                    font-size: $regular;
                    text-align: justify;
                    padding-top: .77rem;
                    font-weight: $normal;
                    text-transform: uppercase;
                }
            }
        }
        .main-desktop{
            display: none;
            background-color: $white;
            border-right: 1px solid $silverChalice;
            nav{
                .drawerPaper{
                    border: 0;
                    width: 220px;
                    overflow: hidden;
                    position: relative;
                    background-color: $white;
                }   
            }
            @include minBreakpoint($minDektop){
                width: 220px;
                display: block;
                position: fixed;
                height: calc(100vh - 48px);
            }
        }
    }
}

.drawerPaper{
    border: 0;
    width: 220px;
    overflow: hidden;
    position: relative;
    background-color: $white !important;
    .list-menu{
        // border-right: 1px solid $silverChalice;
        ul{
            padding: 0;
            div{
                color: $storGray;
                span{
                    font-size: 0.87rem;
                    font-weight: 500;
                }
                i{
                    margin-right: .5rem;
                }
            }
        }
    }
}   